/*
 * Retrieves the composed path from a targetted event.
 * on modern browsers either e.path or e.composedPath(), on old browsers it's manually composed
 */ export var getComposedPath = function getComposedPath(e) {
    if (e.path)
        return e.path;
    if (typeof e.composedPath === "function")
        return e.composedPath();
    var path = [];
    var element = e.target;
    while (element) {
        path.push(element);
        if (element.tagName === "HTML") {
            return path;
        } // eslint-disable-next-line
        element = element.parentElement;
    }
    return path;
};
export var randomString = function randomString() { return Math.random().toString(36).substring(9); };
